<template>

  <div class="timetable-page">
    <v-row justify="center">
      <v-col md="6">
          <v-card v-for="day in days" :key="day.dayNo" class="my-5 py-8 px-6 mx-4 post-card" :color="day.color"  @click="
                    $router.push({
                      name: 'ViewTimetable',
                      params: { roomId: selectedRoom.id, dayNo: day.dayNo},
                    })
                  ">
            <h3 class="white--text"> {{day.title}} </h3>
          </v-card>
      </v-col>
    </v-row>

    <choose-room-dialog
      :visible="!selectedRoom"
      @roomSelected="(r) => (selectedRoom = r)"
    />
  </div>

</template>

<script>
import ChooseRoomDialog from "@components/dialogs/ChooseRoomDialog";
import EventBus from '@utils/event_bus';
export default {
  name: "Timetable",
  data() {
    return { 
      selectedRoom: null,
      
      days: [
        { dayNo: 1, title: "Monday", color: "#54123B" },
        { dayNo: 2, title: "Tuesday", color: "#2F72CC" },
        { dayNo: 3, title: "Wednesday", color: "#00C853" },
        { dayNo: 4, title: "Thursday", color: "#C81912" },
        { dayNo: 5, title: "Friday", color: "#F57C00" },
        { dayNo: 6, title: "Saturday", color: "#388E3C" },
        { dayNo: 7, title: "Sunday", color: "#29C7AC" },
      ], 
    };
  },
  components: {
    ChooseRoomDialog,
  },
  beforeRouteLeave(to, from, next) {
    if(to.name != "ViewTimetable"){
      //kill component
      EventBus.$emit('keep_alive__destroy_view', 'Timetable');

    }
    next();
  },
  created() {
    window.scrollTo(0,0);
  }
}
</script>

<style>

</style>